import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error) {
        console.error(error);
        const notificationService = this.injector.get(NotificationService);

        if (error instanceof HttpErrorResponse) {
            if (!navigator.onLine) {
                return notificationService.notify('No Internet Connection', 'error');
            } else {
                return notificationService.notify(`${error.status} - ${error.message}`, 'error');
            }
        } else {
            return notificationService.notify('Error occured: ' + error, 'error');
        }
    }
}
