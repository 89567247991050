import { Component, OnInit } from "@angular/core";
import { Flag } from "src/app/core/models/flags/flag.model";
import { FlagOption } from "src/app/core/models/flags/flag-option.model";
import { FlagsService } from "src/app/core/services/flags.service";
import { NotificationService } from "src/app/core/services/notification.service";
import { isEqual, cloneDeep } from "lodash";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogComponentData,
} from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "bis-flags",
  templateUrl: "./flags.component.html",
  styleUrls: ["./flags.component.scss"],
})
export class FlagsComponent implements OnInit {
  loading = false;
  flags: Flag[] = [];
  flagsOriginal: Flag[] = [];
  flagOptions: FlagOption[] = [];

  constructor(
    private flagsService: FlagsService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.flagsService.getFlags().subscribe(
      (response) => {
        this.flags = response.flags;
        this.flagsOriginal = cloneDeep(response.flags);
        this.flagOptions = response.flagOptions;
      },
      (errResponse) => {
        console.error(errResponse);
        this.notificationService.notify(errResponse.toString(), "Error");
      }
    );
  }

  get isChanged() {
    return !isEqual(this.flags, this.flagsOriginal);
  }

  save() {
    this.loading = true;

    this.flagsService.updateFlags(this.flags).subscribe(
      (success: boolean) => {
        this.loading = false;

        if (!success) {
          this.notificationService.notify("Failed to save Flags", "Error");
          return;
        }
        this.notificationService.notify("Flags saved successfully.", "Success");
        this.flagsOriginal = cloneDeep(this.flags);
      },
      (errResponse) => {
        this.loading = false;
        console.error(errResponse);
        this.notificationService.notify(errResponse.toString(), "Error");
      }
    );
  }

  cancel() {
    const data: ConfirmationDialogComponentData = {
      title: "Discard Changes?",
      message: "Are you sure you want to discard your changes?",
      primaryOption: "Yes",
      secondaryOption: "Cancel",
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
      position: {
        top: "15%",
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === "Yes") {
        this.reset();
      }
    });
  }

  reset() {
    this.flags = cloneDeep(this.flagsOriginal);
  }
}
