import { Component, OnInit } from "@angular/core";
import { TreasurerMessage } from "src/app/core/models/treasure-message/treasurer-message";
import { NotificationService } from "src/app/core/services/notification.service";
import { TreasurerMessageService } from "src/app/core/services/treasurer-message.service";
import { isEqual, cloneDeep } from "lodash";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogComponentData,
} from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material";

@Component({
  selector: "bis-treasurer-messages",
  templateUrl: "./treasurer-messages.component.html",
  styleUrls: ["./treasurer-messages.component.scss"],
})
export class TreasurerMessagesComponent implements OnInit {
  loading = false;
  messagesOriginal: TreasurerMessage[] = [];
  messages: TreasurerMessage[] = [];

  constructor(
    private messageService: TreasurerMessageService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.messageService.getMessages().subscribe(
      (messages) => {
        this.messages = messages;
        this.messagesOriginal = cloneDeep(messages);
      },
      (errResponse) =>
        this.notificationService.notify(errResponse.toString(), "Dismiss")
    );
  }

  get isChanged() {
    return !isEqual(this.messages, this.messagesOriginal);
  }

  save() {
    this.loading = true;
    this.messageService.saveMessages(this.messages).subscribe(
      (messages) => {
        this.messages = messages;
        this.messagesOriginal = cloneDeep(messages);
        this.notificationService.notify("Save successful!", "Dismiss");
      },
      (errResponse) =>
        this.notificationService.notify(errResponse.toString(), "Dismiss"),
      () => (this.loading = false)
    );
  }

  cancel() {
    const data: ConfirmationDialogComponentData = {
      title: "Discard Changes?",
      message: "Are you sure you want to discard your changes?",
      primaryOption: "Yes",
      secondaryOption: "Cancel",
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
      position: {
        top: "15%",
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === "Yes") {
        this.reset();
      }
    });
  }

  reset() {
    this.messages = cloneDeep(this.messagesOriginal);
  }
}
