import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/core/services/clients.service';
import { ClientsList } from 'src/app/core/models/clients/clients-list.model';

@Component({
  selector: 'bis-control-panel',
  templateUrl: './control-panel.component.html',
  styleUrls: ['./control-panel.component.scss']
})
export class ControlPanelComponent implements OnInit {
  clientsList: ClientsList;
  isLoaded = false;

  constructor(private clientsService: ClientsService) { }

  ngOnInit() {
    this.clientsService.fetchClientsList().subscribe(
      (clients: ClientsList) => {
        this.clientsList = clients;
        this.isLoaded = true;
        console.log(clients);
      },
      errResponse => {
        console.log(errResponse);
      }
    );
  }

}
