import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClientsService } from './clients.service';
import { SchemaTypesEnum } from '../enums/schema-types.enum';
import { SchemaFetchRequest } from '../models/schema/schema-fetch-request.model';
import { SchemaDetail } from '../models/schema/schema-detail.model';

@Injectable({
    providedIn: 'root'
})

export class SchemaFetchService {
    api_url = environment.api_url;

    constructor(private http: HttpClient, private clientsService: ClientsService) { }

    getSchema(schemaType: SchemaTypesEnum, clientId: number) {
        const schemaRequest: SchemaFetchRequest = {
            clientId,
            schemaType
        };

        return this.http.post(this.api_url + 'get-schema', schemaRequest);
    }

    updateSchema(schema: SchemaDetail) {
        return this.http.post(this.api_url + 'update-schema', schema);
    }

    getAllSchemas(clientId: number) {
        return this.http.post(this.api_url + 'get-all-schemas', clientId);
    }
}
