import { NgModule } from "@angular/core";

import { MatButtonModule } from "@angular/material/button";
import {
  MatTableModule,
  MatSelectModule,
  MatInputModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatCardModule,
  MatListModule,
  MatDividerModule,
  MatDialogModule,
  MatRadioModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatBadgeModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatFormFieldModule,
} from "@angular/material";
import { CdkTableModule } from "@angular/cdk/table";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { TextFieldModule } from "@angular/cdk/text-field";

@NgModule({
  imports: [
    CdkTableModule,
    MatButtonModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatDialogModule,
    MatRadioModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    TextFieldModule,
  ],
  exports: [
    CdkTableModule,
    MatButtonModule,
    MatTableModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatDialogModule,
    MatRadioModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    TextFieldModule,
  ],
})
export class MaterialModule {}
