import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Flag, FlagList } from "../models/flags/flag.model";
import { FlagUpdate } from "../models/flags/flag-update.model";

@Injectable({
  providedIn: "root",
})
export class FlagsService {
  api_url = environment.api_url;

  constructor(private http: HttpClient) {}

  getFlags(): Observable<FlagList> {
    return this.http.get(this.api_url + "flags").pipe(
      map((flags: FlagList) => {
        return flags;
      })
    );
  }

  updateFlags(flags: FlagUpdate[]) {
    return this.http.post(this.api_url + "flags", flags);
  }
}
