import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { SchemaDetail } from 'src/app/core/models/schema/schema-detail.model';
import { SchemaTypesEnum } from 'src/app/core/enums/schema-types.enum';
import { SchemaFetchService } from 'src/app/core/services/schema-fetch.service';
import { Client } from 'src/app/core/models/clients/client.model';
import { text } from '@angular/core/src/render3';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'bis-schema-editor',
  templateUrl: './schema-editor.component.html',
  styleUrls: ['./schema-editor.component.scss']
})
export class SchemaEditorComponent implements OnInit, AfterViewInit {
  @Input() schemaDetail: SchemaDetail;

  @ViewChild('editor') editor;
  text = '';
  options: any = { maxLines: 1000, printMargin: false };
  data: any;

  constructor(private schemaService: SchemaFetchService, private notifiCationService: NotificationService) {
  }

  getSchemaName() {
    return SchemaTypesEnum[this.schemaDetail.schemaType];
  }

  ngOnInit() {
    this.data = JSON.parse(this.schemaDetail.schema);

    this.text = JSON.stringify(this.data, null, '\t');
  }

  ngAfterViewInit() {
    this.editor.getEditor().commands.addCommand({
      name: 'showOtherCompletions',
      bindKey: 'Ctrl-.',
    });
  }

  onSubmit() {
    this.schemaDetail.schema = this.text;

    this.schemaService.updateSchema(this.schemaDetail).subscribe(
      (data: any) => this.notifiCationService.notify("Data Saved Successfully.", 'Success'),
      (errResponse: any) => {
        console.log(errResponse);
        this.notifiCationService.notify(errResponse.toString(), 'Error');
      });
  }
}
