import { Component, OnInit } from "@angular/core";
import { SiteSettings } from "src/app/core/models/settings/site-settings.model";
import { NotificationService } from "src/app/core/services/notification.service";
import { SiteSettingsService } from "src/app/core/services/site-settings.service";

@Component({
  selector: "bis-site-settings",
  templateUrl: "./site-settings.component.html",
  styleUrls: ["./site-settings.component.scss"],
})
export class SiteSettingsComponent implements OnInit {
  loading: boolean = false;
  settings: SiteSettings | null = { inMaintainanceMode: false };

  constructor(
    private siteSettingsService: SiteSettingsService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.siteSettingsService.getSettings().subscribe(
      (settings) => (this.settings = settings),
      (errResponse) => this.notificationService.notify(errResponse.toString())
    );
  }

  onChange() {
    this.loading = true;
    this.siteSettingsService.saveSettings(this.settings).subscribe(
      (settings) => {
        this.settings = settings;
        this.notificationService.notify("Settings saved!", "Dismiss");
      },
      (errResponse) => this.notificationService.notify(errResponse.toString()),
      () => {
        this.loading = false;
      }
    );
  }
}
