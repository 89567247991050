import { Component, OnInit, Input } from '@angular/core';
import { Client } from 'src/app/core/models/clients/client.model';

@Component({
  selector: 'bis-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {
  @Input() client: Client;

  constructor() { }

  ngOnInit() {
  }

}
