import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Client } from 'src/app/core/models/clients/client.model';
import { SchemaFetchService } from 'src/app/core/services/schema-fetch.service';
import { SchemaDetail } from 'src/app/core/models/schema/schema-detail.model';
import { SchemaTypesEnum } from 'src/app/core/enums/schema-types.enum';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'bis-schemas-container',
  templateUrl: './schemas-container.component.html',
  styleUrls: ['./schemas-container.component.scss']
})
export class SchemasContainerComponent implements OnInit {
  @Input() client: Client;
  @ViewChild('sidenav') sidenav: MatSidenav;
  isLoad = false;
  schemas: SchemaDetail[];
  selectedSchema: SchemaDetail;
  isExpanded = true;
  isShowing = false;

  constructor(private schemaFetchService: SchemaFetchService) { }

  getSchemaName(value: SchemaTypesEnum) {
    const result = SchemaTypesEnum[value].replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  ngOnInit() {
    this.schemaFetchService.getAllSchemas(this.client.id).subscribe(
      (schemas: SchemaDetail[]) => {
        console.log(schemas);
        this.schemas = schemas.sort((item1, item2) => item1.schemaType > item2.schemaType ? 1 : -1);
        this.selectedSchema = this.schemas[0];
        this.isLoad = true;
      }
    );
  }
}
