import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Flag, FlagList } from "../models/flags/flag.model";
import { FlagUpdate } from "../models/flags/flag-update.model";
import { SiteSettings } from "../models/settings/site-settings.model";

@Injectable({
  providedIn: "root",
})
export class SiteSettingsService {
  api_url = environment.api_url;

  constructor(private http: HttpClient) {}

  getSettings(): Observable<SiteSettings> {
    return this.http.get(this.api_url + "site-settings").pipe(
      map((settings: SiteSettings) => {
        return settings;
      })
    );
  }

  saveSettings(settings: SiteSettings) {
    return this.http.post(this.api_url + "site-settings", settings).pipe(
      map((settings: SiteSettings) => {
        return settings;
      })
    );
  }
}
