import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import { SchemaTypesEnum } from 'src/app/core/enums/schema-types.enum';
import { SchemaDetail } from 'src/app/core/models/schema/schema-detail.model';
import { ClientsService } from 'src/app/core/services/clients.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SchemaFetchService } from 'src/app/core/services/schema-fetch.service';

export interface BlacklistItem {
    name: string;
    isEdit: boolean;
}

@Component({
    selector: 'table-basic-example',
    styleUrls: ['client-panel.component.scss'],
    templateUrl: 'client-panel.component.html',
})
export class ClientPanelComponent implements OnInit {
    selectedSetting: string = 'flags';
    displayedColumns: string[] = ['name', 'isEdit'];
    dataSource: BlacklistItem[] = [];
    newItem: BlacklistItem = { name: '', isEdit: false }
    @ViewChild(MatTable) table: MatTable<any>;
    schemaDetail: SchemaDetail;
    schema: any = {};

    constructor(private clientsService: ClientsService, private schemaFetchService: SchemaFetchService, private notificationService: NotificationService) { }

    ngOnInit(): void {
        this.schemaFetchService.getSchema(SchemaTypesEnum.ApiServiceConfiguration, 1).subscribe(
            (schema: SchemaDetail) => {
                console.log(schema);
                this.schemaDetail = schema;
                this.schema = JSON.parse(schema.schema);
                this.buildBlacklistItems(this.schema);
            },
            errResponse => {
                console.error(errResponse);
            }
        );
    }

    buildBlacklistItems(schema: any): void {
        const blacklistItems: BlacklistItem[] = [];
        if (schema.Parameters.paymentBlacklist) {
            for (let x of schema.Parameters.paymentBlacklist) {
                blacklistItems.push({ name: x, isEdit: false });
            }
        }
        this.dataSource = blacklistItems;
    }

    onEdit(element: BlacklistItem): void {
        element.isEdit = true;
    }

    onDone(element: BlacklistItem): void {
        console.table(this.dataSource);
        element.isEdit = false;
        this.doSave();
    }

    onDelete(element: BlacklistItem): void {
        this.dataSource = this.dataSource.filter(e => e.name !== element.name);
        console.table(this.dataSource);
    }

    doSave(): void {
        if(this.newItem.name != "")
        {
            this.dataSource.push({ name: this.newItem.name, isEdit: false });
            this.newItem.name = '';
            try {
                this.table.renderRows();
            } catch (e) {
                ;
            }
        }
        // update schema
        this.schema.Parameters.paymentBlacklist = this.dataSource.map(i => i.name.trim());
        console.log(this.schema);

        this.schemaDetail.schema = JSON.stringify(this.schema);

        // save to server
        this.schemaFetchService.updateSchema(this.schemaDetail).subscribe(
            (data: any) => this.notificationService.notify('Success'),
            (errResponse: any) => {
                console.log(errResponse);
                this.notificationService.notify(errResponse.toString(), 'Error');
            });
    }
}
