import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    constructor(public snackBar: MatSnackBar, private zone: NgZone) { }

    public notify(message: string, action = 'success', duration = 5000) {
        this.zone.run(() => {
            this.snackBar.open(message, action, { duration });
        });
    }
}
