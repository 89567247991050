export enum SchemaTypesEnum {
    DatabaseConfiguration = 1,
    GridConfiguration = 2,
    FieldsConfiguration = 3,
    CartConfiguration = 4,
    BillDetailConfiguration = 5,
    TotalsExtractionConfiguration = 6,
    PaymentViewConfiguration = 7,
    PaymentDescriptionConfiguration = 8,
    StaticDataConfiguration = 9,
    ApiServiceConfiguration = 10,
    PaymentsServiceConfiguration = 11,
    PaymentsProcessorConfiguration = 12,
    PdfServiceConfiguration = 13,
    NotificationServiceConfiguration = 14,
    TabsConfiguration = 15
}
