import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class AuthentificationService {
    api_url = environment.api_url;

    constructor(private http: HttpClient) { }

    login(password: string) {
        return this.http.post(this.api_url + 'login', { password }).pipe(map((admin: any) => {
            if (admin && admin.token) {
                localStorage.setItem('adminToken', JSON.stringify(admin));
            }

            return admin;
        }));
    }

    logout() {
        localStorage.removeItem('adminToken');
    }
}
