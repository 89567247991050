import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Flag, FlagList } from "../models/flags/flag.model";
import { FlagUpdate } from "../models/flags/flag-update.model";
import { SiteSettings } from "../models/settings/site-settings.model";
import { TreasurerMessage } from "../models/treasure-message/treasurer-message";

@Injectable({
  providedIn: "root",
})
export class TreasurerMessageService {
  api_url = environment.api_url;

  constructor(private http: HttpClient) {}

  getMessages(): Observable<TreasurerMessage[]> {
    return this.http.get(this.api_url + "treasurer-messages").pipe(
      map((messages: TreasurerMessage[]) => {
        return messages;
      })
    );
  }

  saveMessages(messages: TreasurerMessage[]) {
    return this.http.post(this.api_url + "treasurer-messages", messages).pipe(
      map((messages: TreasurerMessage[]) => {
        return messages;
      })
    );
  }
}
