import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ClientsList } from '../models/clients/clients-list.model';

@Injectable({
    providedIn: 'root'
})

export class ClientsService {
    api_url = environment.api_url;
    clientsList: ClientsList;

    constructor(private http: HttpClient) { }

    fetchClientsList() {
        return this.http.get(this.api_url + 'clients').pipe(map((clients: ClientsList) => {
            this.clientsList = clients;
            return clients;
        }));
    }

    getClientsList(): ClientsList {
        return this.clientsList;
    }

    // updateClient(client: ClientData) {
    //     return this.http.post(this.api_url + 'clients/update', client);
    // }
}
