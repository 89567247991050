import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfidentialPropertiesService } from "src/app/core/services/confidential-properties.service";
import { NotificationService } from "src/app/core/services/notification.service";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogComponentData,
} from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "bis-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit {
  updateType: "merge" | "replace" = "merge";
  CSVfile: any = '';
  loading: boolean = false;
  constructor(
    private propertyService: ConfidentialPropertiesService,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    ) {}

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close();
  }

  validateFile(file: any) {
    if (!file || !file.name) {
      return false;
    }
    let filename = file.name;
    filename = filename.split('.');
    const filetype = filename.pop();
    if (filetype.toLowerCase() === 'csv') {
      return true;
    } else {
      return false;
    }
  }

  onFileChange(evt): void {
    if (evt && evt.target && evt.target.files && evt.target.files.length) {
        if (this.validateFile(evt.target.files[0])) {
          this.CSVfile = evt.target.files[0];
        }else{
          this.notificationService.notify("Invalid file", 'Error');
          this.CSVfile = null;
        }
    }
  }

  uploadFile(isReplace: string): void{
    this.loading = true;
    let formdata = new FormData();
    formdata.append('file',this.CSVfile, this.CSVfile.name);
    formdata.append('isReplace',isReplace);
    this.propertyService.saveProperty(formdata).subscribe(
        (data: any) => {
          if(data == true){
            this.notificationService.notify('File successfully uploaded');  
            this.CSVfile = '';
            this.dialog.closeAll();
          } else{
            this.notificationService.notify('Error occurred in file uploading', 'Error');
          }
          this.loading = false;
        },
        (errResponse: any) => {
          if(errResponse.status == 401){
            this.notificationService.notify("You are unauthorized", 'Error');
          }else{
            this.notificationService.notify(errResponse.error.toString(), 'Error');
          }
            this.loading = false;
        });
  }

  
  onUpload() {
    if(this.CSVfile == ''){
      this.notificationService.notify("Please choose the file", 'Error');
      return;
    }
    
    if (this.updateType === "replace") {
      const data: ConfirmationDialogComponentData = {
        title: "Replace All?",
        message:
          "Are you sure you want to replace all existing confidential properties with the contents of this file?",
        primaryOption: "Confirm",
        secondaryOption: "Cancel",
      };

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data,
        position: { top: "15%" },
      });

      dialogRef.afterClosed().subscribe((result: string) => {
        if (result === "Confirm") {
          this.uploadFile("true");
        }
      });
    }else{
      this.uploadFile("false");
    }
  }
}
