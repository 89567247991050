import { Component, OnInit, Input } from '@angular/core';
import { Client } from 'src/app/core/models/clients/client.model';

@Component({
  selector: 'bis-client-container',
  templateUrl: './client-container.component.html',
  styleUrls: ['./client-container.component.scss']
})
export class ClientContainerComponent implements OnInit {
  @Input() client: Client;
  constructor() { }

  ngOnInit() {
  }

}
