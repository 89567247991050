import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

export type ConfirmationDialogComponentData = {
  title: string;
  message: string;
  primaryOption: string;
  secondaryOption: string;
};

@Component({
  selector: "bis-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogComponentData,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  ngOnInit(): void {}

  onPrimaryClick() {
    this.dialogRef.close(this.data.primaryOption);
  }

  onSecondaryClick() {
    this.dialogRef.close(this.data.secondaryOption);
  }
}
