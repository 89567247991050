import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/core/services/authentification.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'bis-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthentificationService, private router: Router, private notificationService: NotificationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    console.log(this.loginForm)
  }

  get passwordError() {
    return this.loginForm.dirty && this.loginForm.get('password').hasError('required') && 'This field is required';
  }

  onLogin() {
    this.loginForm.markAsDirty();
    
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService.login(this.loginForm.get('password').value).pipe(first())
        .subscribe(
          data => {
            this.loading = false;
            this.notificationService.notify('Login successful', 'Dismiss');
            if (data.type === "client") {
              this.router.navigate(['clientPanel']);
            } else {
              this.router.navigate(['panel']);
            }
          },
          error => {
            this.loading = false;
            console.log(error);

            if (error.status === 401) {
              this.notificationService.notify("Incorrect password");
              return;
            }

            this.notificationService.notify(error.message, 'Dismiss');
          }
        );
    }
  }
}
