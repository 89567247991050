import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfidentialPropertyDetails } from "../models/confidential-property/confidential-propety-details.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ConfidentialPropertiesService {
  api_url = environment.api_url;

  constructor(private http: HttpClient) {}

  findProperty(propertyId: string): Observable<ConfidentialPropertyDetails> {
    return this.http
      .post(this.api_url + "get-confidential-property", {
        propertyId,
      })
      .pipe(
        map((confidential: ConfidentialPropertyDetails) => {
          return confidential;
        })
      );
  }

  saveProperty(propertyfile: any) {
    return this.http
      .post(this.api_url + "save-file-confidential-property",propertyfile);
  }

  updateProperty(property: ConfidentialPropertyDetails){
    return this.http
      .post(this.api_url + "update-confidential-property",  property)
  }
}
