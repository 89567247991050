import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AceEditorModule } from "ng2-ace-editor";
import { ErrorsHandler } from "../core/errorHandling/errors.handler";
import { AdminAuthorizationInterceptor } from "../core/interceptors/admin-authorization.interceptor";
import { SharedModule } from "../shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ClientPanelComponent } from "./components/client-panel/client-panel.component";
import { ClientContainerComponent } from "./components/control-panel/client-container/client-container.component";
import { ClientDetailComponent } from "./components/control-panel/client-container/client-detail/client-detail.component";
import { SchemaEditorComponent } from "./components/control-panel/client-container/schemas-container/schema-editor/schema-editor.component";
import { SchemasContainerComponent } from "./components/control-panel/client-container/schemas-container/schemas-container.component";
import { ControlPanelComponent } from "./components/control-panel/control-panel.component";
import { LoginComponent } from "./components/login/login.component";
import { ConfidentialPropertiesComponent } from "./components/client-panel/confidential-properties/confidential-properties.component";
import { FlagsComponent } from "./components/client-panel/flags/flags.component";
import { UploadDialogComponent } from "./components/client-panel/confidential-properties/upload-dialog/upload-dialog.component";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { SiteSettingsComponent } from "./components/client-panel/site-settings/site-settings.component";
import { TreasurerMessagesComponent } from "./components/client-panel/treasurer-messages/treasurer-messages.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClientPanelComponent,
    ControlPanelComponent,
    ClientContainerComponent,
    ClientDetailComponent,
    ConfidentialPropertiesComponent,
    SchemasContainerComponent,
    SchemaEditorComponent,
    UploadDialogComponent,
    ConfirmationDialogComponent,
    FlagsComponent,
    SiteSettingsComponent,
    TreasurerMessagesComponent,
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserModule,
    AceEditorModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  entryComponents: [UploadDialogComponent, ConfirmationDialogComponent],
  providers: [
    { provide: ErrorHandler, useClass: ErrorsHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AdminAuthorizationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
