import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ConfidentialPropertyDetails } from "src/app/core/models/confidential-property/confidential-propety-details.model";
import { ConfidentialPropertiesService } from "src/app/core/services/confidential-properties.service";
import { UploadDialogComponent } from "./upload-dialog/upload-dialog.component";
import { cloneDeep, isEqual } from "lodash";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogComponentData,
} from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "src/app/core/services/notification.service";

@Component({
  selector: "bis-confidential-properties",
  templateUrl: "./confidential-properties.component.html",
  styleUrls: ["./confidential-properties.component.scss"],
})
export class ConfidentialPropertiesComponent implements OnInit {
  form: FormGroup;
  confidentialProperty: ConfidentialPropertyDetails | null = null;
  confidentialPropertyOriginal: ConfidentialPropertyDetails | null = null; // copy for tracking changes and resetting
  loading: boolean = false;
  loadingsearch: boolean = false;
  isSearched: boolean = false;

  constructor(
    private propertyService: ConfidentialPropertiesService,
    public dialog: MatDialog,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      propertyId: new FormControl("", [Validators.required]),
    });
  }

  search() {
    if (!this.form.valid) {
      return;
    }

    this.loadingsearch = true;
    this.refreshSearch();    
  }

  refreshSearch() {
    const propertyId = this.form.controls.propertyId.value;

    this.propertyService.findProperty(propertyId).subscribe(
      (property) => {
        this.confidentialPropertyOriginal = property;
        this.confidentialProperty = cloneDeep(property);   
        this.loadingsearch = false;    
        this.isSearched = true;
      },
      (errResponse: any) => {
        console.log(errResponse);
        if(errResponse.status == 401){
          this.notificationService.notify("You are unauthorized", 'Error');
        }else{
          this.notificationService.notify(errResponse.error.toString(), 'Error');
        }
        this.loadingsearch = false;
        this.isSearched = true;
      });
  }

  onSupressPropertyChange(evt){
    let isChecked = evt.checked;
    this.confidentialProperty.confidential = isChecked;
      this.confidentialProperty.bills.map((item)=>{
        item.confidential = isChecked;
      });
  }

  onConfidentialChange(evt){
    if(this.confidentialProperty.confidential && !evt.checked){
      this.confidentialProperty.confidential = false;
    }
  }

  openUploadDialog() {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      position: { top: "15%" },
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  get isChanged() {
    return !isEqual(
      this.confidentialProperty,
      this.confidentialPropertyOriginal
    );
  }

  cancel() {
    const data: ConfirmationDialogComponentData = {
      title: "Discard Changes?",
      message: "Are you sure you want to discard your changes?",
      primaryOption: "Yes",
      secondaryOption: "Cancel",
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data,
      position: {
        top: "15%",
      },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === "Yes") {
        this.resetProperty();
      }
    });
  }

  save() {
    this.loading = true;
    this.propertyService.updateProperty(this.confidentialProperty).subscribe(
    (data: any) => {
      if(data == true){
        this.notificationService.notify('Confidential properties are updated.');  
        this.refreshSearch();
      } else{
        this.notificationService.notify('Error occurred in updating confidential properties', 'Error');
      }
      this.loading = false;
    },
    (errResponse: any) => {
      if(errResponse.status == 401){
        this.notificationService.notify("You are unauthorized", 'Error');
      }else{
        this.notificationService.notify(errResponse.error.toString(), 'Error');
      }
        this.loading = false;
    });
  }

  resetProperty() {
    this.confidentialProperty = cloneDeep(this.confidentialPropertyOriginal);
  }
}
